import {
  faArrowUp,
  faArrows,
  faDownload,
  faEllipsisVertical,
  faShare,
  faCaretRight,
  faCaretDown,
  faFolder,
  faTrash,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faUser,
  faUsers,
  faBadgeCheck,
  faAddressCard,
  faPlusCircle,
  faEdit,
  faFileDownload,
  faFileSignature,
  faCircleQuestion,
  faCircle,
  faHighlighter,
  faStop,
  faStopCircle,
  faCheck,
  faRedo,
  faXmark,
  faCircleXmark,
  faCircleCheck,
  faChevronRight,
  faChevronDoubleRight,
  faPlus,
  faQuoteRight,
  faBookmark,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronDoubleLeft,
  faPrint,
  faMapMarkerPlus,
  faBullseyeArrow,
  faGear,
  faRedoAlt,
  faEye,
  faEyeSlash,
  faFileExport,
  faUndoAlt,
  faKey,
  faUserEdit,
  faPen,
  faTrashCan,
  faFileCheck,
  faFolderTree,
  faSlidersV,
  faUserFriends,
  faFolderOpen,
  faTasks,
  faClipboardListCheck,
  faBook,
  faCircleInfo,
  faAsterisk,
  faQuestionSquare,
  faCircleExclamation,
  faCommentsAlt,
  faUserShield,
  faSlidersVSquare,
  faEnvelope,
  faClock,
  faPlayCircle,
  faGripVertical,
  faArchive,
  faFolderTimes,
  faExclamationTriangle,
  faBars,
  faIdCard,
  faSlidersH,
  faSpinnerThird,
  faLink,
  faList,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faRotateRight,
  faDiamondExclamation,
} from '@fortawesome/pro-solid-svg-icons'

export const solidLibrary = {
  faArrowUp,
  faArrows,
  faDownload,
  faEllipsisVertical,
  faShare,
  faCaretRight,
  faCaretDown,
  faFolder,
  faTrash,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faUser,
  faUsers,
  faBadgeCheck,
  faAddressCard,
  faPlusCircle,
  faEdit,
  faFileDownload,
  faCircleQuestion,
  faCircle,
  faHighlighter,
  faStop,
  faStopCircle,
  faCheck,
  faRedo,
  faXmark,
  faCircleXmark,
  faCircleCheck,
  faPlus,
  faQuoteRight,
  faBookmark,
  faChevronUp,
  faChevronLeft,
  faChevronDoubleLeft,
  faChevronRight,
  faChevronDoubleRight,
  faPrint,
  faMapMarkerPlus,
  faBullseyeArrow,
  faGear,
  faRedoAlt,
  faFileSignature,
  faChevronDown,
  faEye,
  faEyeSlash,
  faFileExport,
  faUndoAlt,
  faKey,
  faUserEdit,
  faPen,
  faTrashCan,
  faFileCheck,
  faFolderTree,
  faSlidersV,
  faUserFriends,
  faFolderOpen,
  faTasks,
  faClipboardListCheck,
  faBook,
  faCircleInfo,
  faAsterisk,
  faQuestionSquare,
  faCircleExclamation,
  faCommentsAlt,
  faUserShield,
  faSlidersVSquare,
  faEnvelope,
  faClock,
  faPlayCircle,
  faGripVertical,
  faArchive,
  faFolderTimes,
  faExclamationTriangle,
  faBars,
  faIdCard,
  faSlidersH,
  faSpinnerThird,
  faLink,
  faList,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faRotateRight,
  faDiamondExclamation,
}
