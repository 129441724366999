<template>
  <div class="d-inline-block">
    <b class="MemberDetailsModal-name clickable ignore-draggable-elements"
       @click="openModal"
    >
      {{ member.fullName }}
    </b>
    <AppDialog v-if="userProfile"
               :is-open="modalIsOpen"
               ok-only
               :ok-text="$t('common.close')"
               @ok="modalIsOpen = false"
               @cancel="modalIsOpen = false"
    >
      <template #title>{{ $t('project.members.dialogs.MemberDetailsDialog.userProfile') }}</template>
      <template #body>
        <v-row align="center">
          <v-col cols="auto">
            <v-avatar color="primary"
                      size="60"
                      class="text-h1 white--text"
            >
              {{ userProfile.initials }}
            </v-avatar>
          </v-col>
          <v-col class="text--primary">
            <div class="text-h3 font-weight-bold">
              {{ userProfile.fullName }}
              <AppTooltip v-if="!userProfile.missingInfoToSign && userProfile.isActive" top>
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon :icon="['fas', 'badge-check']"
                                     class="mx-1 tertiary--text text--lighten-1"
                                     v-bind="attrs"
                                     v-on="on"
                  ></font-awesome-icon>
                </template>
                <span>{{ $t('project.members.dialogs.MemberDetailsDialog.identityVerified') }}</span>
              </AppTooltip>
            </div>
            <div class="font-italic my-1">{{ userProfile.company }}</div>
            <div>{{ userProfile.email }}</div>
          </v-col>
        </v-row>
        <v-row v-if="isCurrentUserPm && isInPmGroup">
          <v-col>
            <v-switch v-model="computedIsHiddenPm"
                      dense
                      hide-details
                      :loading="patchIsHiddenPmPending || refreshProfileLoading"
                      :label="$t('project.members.dialogs.MemberDetailsDialog.isHiddenPM')"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppDialog from '../../../common/dialogs/AppDialog'

export default {
  name: 'MemberDetailsModal',
  components: {
    AppDialog,
    AppTooltip,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    isInPmGroup: {
      type: Boolean,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      modalIsOpen: false,
      userProfile: null,
      loading: false,
      refreshProfileLoading: false,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapState('groups', ['patchIsHiddenPmPending']),
    computedIsHiddenPm: {
      get () {
        return this.userProfile.isHiddenPm
      },
      set (value) {
        this.preparePatchIsHiddenPm(value)
      },
    },
  },
  methods: {
    ...mapActions('groups', ['GET_USER_PROFILE', 'PATCH_IS_HIDDEN_PM']),
    async openModal () {
      this.loading = true
      try {
        const response = await this.GET_USER_PROFILE({
          mnemo: this.mnemo,
          userId: this.member.id,
        })
        this.userProfile = response.data
        this.modalIsOpen = true
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async preparePatchIsHiddenPm (value) {
      try {
        await this.PATCH_IS_HIDDEN_PM({
          mnemo: this.mnemo,
          userId: this.member.id,
          data: {
            hidePm: value,
          },
        })
        this.prepareGetUserProfile()
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.members.dialogs.MemberDetailsDialog.patchIsHiddenPmSuccess'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.members.dialogs.MemberDetailsDialog.patchIsHiddenPmError'),
        })
      }
    },
    async prepareGetUserProfile () {
      this.refreshProfileLoading = true
      try {
        const response = await this.GET_USER_PROFILE({
          mnemo: this.mnemo,
          userId: this.member.id,
        })
        this.userProfile = response.data
        this.$emit('update:member', this.userProfile)
      } catch (e) {
        console.error(e)
      } finally {
        this.refreshProfileLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.MemberDetailsModal-name {
  &:hover {
    text-decoration: underline;
  }
}
</style>
