<template>
  <v-card
    class="Member rounded pa-2 mb-2"
    color="#f5f5f5"
    flat
    active
    :class="{ item: profile.id !== member.id, 'ignore-draggable-elements': profile.id === member.id }"
    :style="{ cursor: profile.id !== member.id && isCurrentUserPm ? 'grab' : '' }"
    :elevation="hover && isCurrentUserPm && profile.id !== member.id ? 3 : 0"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-row align="center" no-gutters>
      <v-col>
        <div :class="{ 'user-in-group': profile.id === member.id }">
          <MemberDetailsModal :mnemo="mnemo"
                              :member="member"
                              :is-in-pm-group="group.isPm"
                              @update:member="$emit('update:member', $event)">
          </MemberDetailsModal>
          <template v-if="!currentRoom.isDataroom">
            <AppTooltip v-if="isCurrentUserPm && idCheckOk" top>
              <template v-slot:activator="{ on, attrs }">
                <font-awesome-icon :icon="['fas', 'badge-check']"
                                  class="mx-1 tertiary--text text--lighten-1"
                                  v-bind="attrs"
                                  v-on="on"
                ></font-awesome-icon>
              </template>
              <span>{{$t('project.members.Member.identityVerified')}}</span>
            </AppTooltip>
            <AppTooltip v-if="isCurrentUserPm && !member.isRedactedForInactivity && noIdCheck" top>
              <template v-slot:activator="{ on, attrs }">
                <font-awesome-icon :icon="['fas', 'address-card']"
                                  color="grey"
                                  class="mx-1"
                                  v-bind="attrs"
                                  v-on="on"
                ></font-awesome-icon>
              </template>
              <span>{{$t('project.members.Member.identityVerificationNotPerformed', {name: member.fullName})}}</span>
            </AppTooltip>
          </template>
          <v-chip v-if="isCurrentUserPm && member.isHiddenPm"
                  color="#FFE2FF"
                  text-color="#992B99"
                  label
                  small
                  class="ml-1"
          >
            <app-text variant="x-small-bold" class="mx-1 mb-0">
              {{ $t('project.members.Member.hiddenPm')}}
            </app-text>
          </v-chip>
        </div>
        <div class="text-caption mt-1">{{ member.company }}</div>
      </v-col>
      <v-col class="align-self-start" cols="auto">
        <!-- BUTTON DELETE MEMBER -->
        <MemberDeleteDialog v-if="isCurrentUserPm && member.id !== profile.id" :member="member" style="margin-top: -5px;" />
      </v-col>
    </v-row>
    <v-row v-if="member.isRedactedForInactivity" class="mt-1" no-gutters>
      <v-col class="text-caption ignore-draggable-elements quaternary lighten-3 px-2 py-1 mr-1 rounded-sm mt-1 w-100">
        {{$t('project.members.Member.isRedactedForInactivity')}}
      </v-col>
    </v-row>
    <v-row v-else-if="!member.isActive && isCurrentUserPm" class="mt-1" no-gutters>
      <v-col>
        <!-- NOT ACTIVATED -->
        <AppTooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              :disabled="sendGlobalReminderLoading"
              depressed
              height="30"
              width="100%"
              class="text-caption ignore-draggable-elements quaternary lighten-3 px-2 py-1 mr-1 rounded-sm mt-1"
              v-on="on"
              @click="sendEmail"
            >
              {{$t('project.members.Member.accountNotActivated')}}
              <v-spacer></v-spacer>
              <v-icon small color="quaternary" class="ml-2">fa-envelope</v-icon>
            </v-btn>
          </template>
          <span>{{$t('project.members.Member.sendFollowUpEmail')}}</span>
        </AppTooltip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import AppTooltip from '@/common/AppTooltip'

import MemberDeleteDialog from './dialogs/MemberDeleteDialog'
import MemberDetailsModal from './dialogs/MemberDetailsDialog'

export default {
  name: 'Member',
  components: {
    MemberDetailsModal,
    MemberDeleteDialog,
    AppTooltip,
  },
  props: {
    member: Object,
    group: Object,
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      hover: false,
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapState('room', ['currentRoom']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapState('groups', ['sendGlobalReminderLoading']),
    idCheckOk () {
      return !this.member.missingInfoToSign && this.member.isActive
    },
    noIdCheck () {
      if (this.member.isActive) {
        if (Array.isArray(this.member.missingInfoToSign)) {
          return this.member.missingInfoToSign.includes('idCheck')
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions('groups', ['CHASE_MEMBER']),
    sendEmail () {
      this.CHASE_MEMBER(this.member.id)
        .then(() => {
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.members.Member.messages.successChaseUser'),
          })
        })
        .catch(error => {
          if (error.response?.status === 403 && error.response?.data?.errorCode === 'ERR_WAIT_BEFORE_CHASING') {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.Member.messages.errorChaseUserWait'),
            })
          } else if (error.response?.status === 409 && error.response?.data?.errorCode === 'ERR_USER_ALREADY_ACTIVE') {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.Member.messages.errorChaseUserAlreadyActive'),
            })
          } else {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.Member.messages.errorChaseUser'),
            })
          }
        })
    },
  },
}
</script>

<style scoped lang="scss">
.list-members-item:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}
.user-in-group {
  color: var(--v-primary-base);
  font-weight: 800 !important;
}
.Member.sortable-ghost,
.Member.sortable-chosen,
.Member.sortable-drag {
  background-color: var(--v-primary-lighten4) !important;
  border: 2px dashed var(--v-primary-base) !important;
}
</style>
