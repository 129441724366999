<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :ok-text="$t('common.confirm')"
             :retain-focus="false"
             :ok-loading="postRoomProcessing"
             :ok-disabled="postRoomProcessing"
             :cancel-disabled="postRoomProcessing"
             @ok="preparePostRoom"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('projects.dialogs.ProjectsNewSubscriptionDialog.projectCreation') }}</template>
    <template #body>
      <form novalidate @keydown.enter="preparePostRoom">
        <v-row>
          <v-col>
            <Alert v-model="alert"
            >
              {{ $t('projects.dialogs.ProjectsNewSubscriptionDialog.optionsEditableLater') }}
            </Alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <AppTextField v-model.trim="room.name"
                          class="required"
                          :label="$t('projects.dialogs.ProjectsNewSubscriptionDialog.projectName')"
                          :error-messages="roomNameError"
                          maxLength="100"
                          counter="100"
            />
          </v-col>
          <v-col cols="12" md="6">
            <AppTextField v-model.trim="room.cmRef"
                          :label="$t('projects.dialogs.ProjectsNewSubscriptionDialog.customerRef')"
                          maxLength="100"
                          counter="100"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <AppDatePicker ref="picker"
                           v-model="room.expClosingDate"
                           :label="$t('projects.dialogs.ProjectsNewSubscriptionDialog.plannedCompletionDate')"
                           class="w-100"
                           min="2000-01-01"
            />
          </v-col>
          <v-col cols="12" md="6">
            <template v-if="subscriptionLabelsPending">
              <v-skeleton-loader type="text"/>
            </template>
            <template v-else>
              <AppSelect v-if="computedRoomsLabels"
                         v-model="room.labelId"
                         :label="$t('projects.dialogs.ProjectsNewSubscriptionDialog.status')"
                         :items="computedRoomsLabels"
                         item-value="id"
              >
                <template #item="{item}">
                  <div v-if="item.id === null">
                    {{ item.text }}
                  </div>
                  <AppProjectLabel v-else :label="item"/>
                </template>
                <template #selection="{ item }">
                  <div v-if="item.id === null">
                    {{ item.text }}
                  </div>
                  <AppProjectLabel v-else :label="item"/>
                </template>
              </AppSelect>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              v-model="room.optHideGroups"
              hide-details
              class="ProjectsDialogNew-checkbox mt-0 mb-2 pt-0"
              :label="$t('projects.dialogs.ProjectsNewSubscriptionDialog.hideGroups')"
              color="success"
            />
            <v-switch
              v-model="room.optAllowGuestBibles"
              hide-details
              class="ProjectsDialogNew-checkbox mt-0 pt-0"
              :label="$t('projects.dialogs.ProjectsNewSubscriptionDialog.optAllowGuestBibles')"
              color="success"
            />
          </v-col>
        </v-row>
      </form>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppDatePicker from '@/common/AppDatePicker'
import AppProjectLabel from '@/common/AppProjectLabel'
import AppSelect from '@/common/AppSelect'
import AppTextField from '@/common/AppTextField'
import AppDialog from '@/common/dialogs/AppDialog'
import { RoomToPost } from '@/models/room.model'
import { GET_SUBSCRIPTION_LABELS } from '@/store/modules/rooms/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { ROOM_TYPES } from '../../common/utils/roomTypes'
import { dialogMixin } from '../../mixins/dialogMixin'
export default {
  name: 'ProjectsNewSubscriptionDialog',
  components: { AppDialog, Alert, AppTextField, AppDatePicker, AppSelect, AppProjectLabel },
  mixins: [validationMixin, dialogMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  validations: {
    room: {
      name: {
        required,
        maxLength: maxLength(100),
      },
    },
  },
  data () {
    return {
      alert: true,
      room: new RoomToPost(),
    }
  },
  computed: {
    ...mapState('room', ['postRoomProcessing']),
    ...mapState('rooms', ['subscriptionLabels', 'roomTypes', 'subscriptionLabelsPending']),
    computedRoomsLabels () {
      const labels = []
      labels.push({
        id: null,
        text: this.$t('projects.dialogs.ProjectsNewSubscriptionDialog.noLabel'),
      })
      if (this.subscriptionLabels) {
        labels.push(...this.subscriptionLabels)
      }
      return labels
    },
    roomNameError () {
      const errors = []
      if (!this.$v.room.name.$dirty) return errors
      !this.$v.room.name.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('projects.dialogs.ProjectsNewSubscriptionDialog.projectName') }))
      !this.$v.room.name.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('projects.dialogs.ProjectsNewSubscriptionDialog.projectName') }))
      return errors
    },
  },
  async mounted () {
    try {
      await this.GET_SUBSCRIPTION_LABELS(
        {
          params: {
            isDataroom: false,
          },
        },
      )
    } catch (e) {
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('projects.dialogs.ProjectsNewSubscriptionDialog.getRoomsLabelsError'),
      })
    }
  },
  methods: {
    ...mapActions('rooms', [GET_SUBSCRIPTION_LABELS]),
    ...mapActions('room', ['postRoom']),
    async preparePostRoom () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.room.type = this.roomTypes.find((roomType) => roomType.type === ROOM_TYPES.PROJECT_UNLIMITED).id
        try {
          await this.postRoom(this.room)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('projects.dialogs.ProjectsNewSubscriptionDialog.postRoomSuccess'),
          })
        } catch (error) {
          console.error(error)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('projects.dialogs.ProjectsNewSubscriptionDialog.postRoomError'),
          })
        }
      }
    },
  },
}
</script>
